html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.imgRounded {
  border-radius: 8px;
}

.grab-element {
  cursor: grab;
  /* Additional styles for your element */
}

.grab-element:active {
  cursor: grabbing;
}

* {
  box-sizing: border-box;

  /* for development only */
  /* border: 1px solid red */
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}

.MuiPhoneNumber-flagButton {
  width: min-content;
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

@media only screen and (min-width: 600px) {
  body {
    overflow-y: scroll;
  }
}
